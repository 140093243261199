import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import AboveTheFoldForm from "../../AboveTheFold_form"

const AboveTheFoldPro: React.FunctionComponent<{}> = () => {
  const data = useStaticQuery(graphql`
    fragment HeroImage on File {
      childImageSharp {
        fluid(
          maxWidth: 2880
          srcSetBreakpoints: [800, 1275, 1440, 1920, 2880]
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      mdx(slug: { eq: "professionnels" }) {
        id
        frontmatter {
          templateKey
          header {
            background {
              ...HeroImage
            }
            overlay
            tagline
            title
          }
        }
      }
    }
  `)
  const headerData = data.mdx.frontmatter.header;
  return (
    <AboveTheFoldForm
      image={headerData.background}
      title={headerData.title}
      text={headerData.tagline}
      overlay={headerData.overlay}
    />
  )
}

export default AboveTheFoldPro
