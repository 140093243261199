import React from "react"
import TestimonialLogosSlider from "../../TestimonialLogosSlider"
import "./TestimonialsPro.scss"

const TestimonialsPro: React.FunctionComponent = () => {
  return (
    <section className="testimonials-pro section-padding">
      <h2 className="heading heading--section-title">
        Ils nous font confiance
      </h2>
      <section className="slides">
        <TestimonialLogosSlider location="pro"/>
      </section>
    </section>
  )
}

export default TestimonialsPro
