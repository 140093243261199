import { GatsbyImageProps } from "gatsby-image"
import React from "react"
import "./TwoCols.scss"
import Img from "gatsby-image"

export interface ITwoColsProps {
  image: string | GatsbyImageProps
  inverted?: boolean
  imgStyle?: object;
}

const TwoCols: React.FunctionComponent<ITwoColsProps> = props => {
  const renderImage = (imageProp: string | GatsbyImageProps) => {
    return typeof imageProp === "string" ? (
      <img src={imageProp} className="two-cols-img" />
    ) : (
      <Img
        {...imageProp}
        className="two-cols-img"
        imgStyle={props.imgStyle}
      />
    )
  }
  const imageComponent= renderImage(props.image);
  return (
    <div
      className={`two-cols__columns-wrapper ${
        props.inverted ? "two-cols__columns-wrapper--inverted" : ""
      }`}
    >
      <div className="col-1 markdown">{props.inverted ? props.children : imageComponent}</div>
      <div className="col-2 markdown">{props.inverted ? imageComponent : props.children}</div>
    </div>
  )
}

export default TwoCols
