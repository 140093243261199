import React from "react"

import Layout from "../components/Layout"
import Partnership from "../components/pages/index/Partnership"
import AboveTheFoldPro from "../components/pages/nos-offres-pro/AboveTheFoldPro"
import PartnershipTypes from "../components/pages/nos-offres-pro/PartnershipTypes"
import TestimonialsPro from "../components/pages/nos-offres-pro/TestimonialsPro"
import SEO from "../components/Seo"

import "./professionnels.scss"

const Professionnels: React.FunctionComponent = props => {
  return (
    <Layout activePage="professionnels">
      <SEO
        title="Nos offres partenaires pour une solution zéro déchet"
        description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
        keywords={[
          "consigne",
          "emballage",
          "zéro",
          "déchets",
          "restauration",
          "btb",
          "b2b",
        ]}
      />
      <AboveTheFoldPro />
      <Partnership scrollToTop />
      <TestimonialsPro />
      <PartnershipTypes />
      <p className="professionnels__footnote">*IFOP pour WWF (2019)</p>
    </Layout>
  )
}

export default Professionnels
