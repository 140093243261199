import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import React from "react"
import LogosSlider, { ILogo } from "../../LogosSlider"
import { ILogoNode } from "../../TestimonialLogosSlider"

import TwoCols from "../../TwoCols"

import "./PartnershipTypes.scss"

const PartnershipTypes: React.FunctionComponent = () => {
  const images: {
    cafeteria: { childImageSharp: GatsbyImageProps }
    plat: { childImageSharp: GatsbyImageProps }
    grandeDistribution: { childImageSharp: GatsbyImageProps }
    logos: {
      frontmatter: {
        independants: {
          displayMode: "slider" | "static"
          logos: ILogoNode[]
        }
        entreprise: {
          displayMode: "slider" | "static"
          logos: ILogoNode[]
        }
        grandeDistribution: {
          displayMode: "slider" | "static"
          logos: ILogoNode[]
        }
      }
    }
  } = useStaticQuery(graphql`
    fragment PartnerLogo on File {
      childImageSharp {
        fluid(maxWidth: 150, srcSetBreakpoints: [150]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
    query LogosAndImages {
      cafeteria: file(
        relativePath: { eq: "nos-offres-pro/cafeteria-entreprise.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, srcSetBreakpoints: [500, 1000]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      plat: file(
        relativePath: { eq: "nos-offres-pro/restaurant-independant.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, srcSetBreakpoints: [500, 1000]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      grandeDistribution: file(
        relativePath: { eq: "nos-offres-pro/grande-distribution.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, srcSetBreakpoints: [500, 1000]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logos: mdx(slug: { eq: "partnershipTypes" }) {
        frontmatter {
          independants {
            displayMode
            logos {
              height
              image {
                ...PartnerLogo
              }
              title
            }
          }
          entreprise {
            displayMode
            logos {
              height
              image {
                ...PartnerLogo
              }
              title
            }
          }
          grandeDistribution {
            displayMode
            logos {
              height
              image {
                ...PartnerLogo
              }
              title
            }
          }
        }
        slug
      }
    }
  `)
  const independantsLogosData = images.logos.frontmatter.independants
  const formattedIndependantsLogos = independantsLogosData.logos.map(
    logoData => {
      return {
        alt: `Logo de ${logoData.title}`,
        fluidObject: logoData.image.childImageSharp?.fluid,
        height: logoData.height,
        publicURL: logoData.image.publicURL,
      }
    }
  )
  const entrepriseLogosData = images.logos.frontmatter.entreprise
  const formattedEntrepriseLogos: ILogo[] = entrepriseLogosData.logos.map(
    logoData => {
      return {
        alt: `Logo de ${logoData.title}`,
        fluidObject: logoData.image.childImageSharp?.fluid,
        height: logoData.height,
        publicURL: logoData.image.publicURL,
      }
    }
  )
  const grandeDistributionLogosData =
    images.logos.frontmatter.grandeDistribution
  const formattedGrandeDistributionLogos = grandeDistributionLogosData.logos.map(
    logoData => {
      return {
        alt: `Logo de ${logoData.title}`,
        fluidObject: logoData.image.childImageSharp?.fluid,
        height: logoData.height,
        publicURL: logoData.image.publicURL,
      }
    }
  )
  return (
    <section className="partnership-types section-padding">
      <ul className="anchor-links">
        <li>
          <a
            href="#independant"
            className="link button button--small button--color-pink"
          >
            Restaurant indépendant
          </a>
        </li>
        <li>
          <a
            href="#entreprise"
            className="link button button--small button--color-pink"
          >
            Cafétéria d'entreprise
          </a>
        </li>
        <li>
          <a
            href="#distribution"
            className="link button button--small button--color-pink"
          >
            Grande distribution
          </a>
        </li>
        <li>
          <a
            onClick={() => scrollTo(0, 0)}
            className="link button button--small button--color-pink"
          >
            Autre
          </a>
        </li>
      </ul>
      <section className="two-cols" id="independant">
        <div className="container">
          <h2 className="heading heading--section-title">
            Restaurant indépendant
          </h2>
          <TwoCols
            image={images.plat.childImageSharp}
            imgStyle={{ objectFit: "contain" }}
          >
            <p className="preline">
              {`Vous souhaitez rejoindre le 1er réseau de restos sans emballage jetable ?
            Vous aimeriez anticiper la loi Agec qui vise à réduire l’usage unique ?
            Vous êtes un resto qui souhaite agir pour la planète ? Rejoignez le mouvement !
            `}
            </p>
            <p>{`La Consigne GreenGo vous apporte une solution avec un service complet d’emballages réutilisables.
            La vraie force du réseau ? La proximité entre les points de collecte.`}</p>
            <br />
            <Link
              to="/blog/paris-semballe-le-projet/"
              className="link link--underline"
            >
              Découvrir le projet Paris s’emballe {`>`}
            </Link>
            {/* <Testimonial
                image={images.navigli.childImageSharp.fixed}
                text="On a eu la chance de construire le service avec La Consigne GreenGo et on peut dire que c’est une réussite : il s’adapte à tout type de restaurants. L’équipe est super flexible : ils fournissent même des contenants à pizza."
                signature="Pierre, Navigli - 52 rue d’Enghien 75010"
              /> */}
          </TwoCols>
          <p
            onClick={() => scrollTo(0, 0)}
            className="link button button--color-pink"
          >
            Se lancer
          </p>
          <React.Fragment>
            {independantsLogosData.displayMode === "slider" ? (
              <div className="slides logos--slider">
                <LogosSlider logos={formattedIndependantsLogos} />
              </div>
            ) : (
              <div className="static-logos">
                {formattedIndependantsLogos.map(image =>
                  image.fluidObject ? (
                    <Img
                      key={`partnershipType-restaurant-${image.alt}`}
                      fluid={image.fluidObject}
                      style={{ height: image.height, width: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      alt={image.alt}
                    />
                  ) : (
                    <img
                      loading="lazy"
                      src={image.publicURL}
                      height={image.height}
                    />
                  )
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      </section>
      <section id="entreprise" className="two-cols">
        <div className="container">
          <h2 className="heading heading--section-title">
            Cafétéria d'entreprise
          </h2>
          <TwoCols
            image={images.cafeteria.childImageSharp}
            imgStyle={{ objectFit: "contain" }}
            inverted
          >
            <p>
              Vous souhaitez fédérer vos employés autour d’une mission RSE ?
            </p>
            <p>
              La Consigne GreenGo offre un service 100% autonome qui s’adapte à
              tout type de structure : cafétéria, restaurant, bureau ou click
              and collect.
            </p>
            <p>
              {" "}
              Dans tous les cas, notre équipe s’adapte à vos besoins, vos envies
              et vos contraintes.
            </p>
            <br />
            <p>Qualité d’exécution reconnue par le secteur :</p>
            <ul>
              <li>90% de taux de retour dans nos collecteurs</li>
              <li>
                Interfaçage avec tous les logiciels d’encaissement et toute
                application existante
              </li>
              <li>Accompagnement sur-mesure</li>
            </ul>
            <br />
            {/* <Testimonial
                image={images.decathlon?.childImageSharp.fixed}
                text="Nous sommes ravis du service La Consigne GreenGo. Nous avons eu un retour sur investissement positif dès la première année et nos employés sont ravis d’avoir un impact sur la planète."
                signature="Anne, Décathlon - Directrice RSE"
              /> */}
          </TwoCols>
          <p
            onClick={() => scrollTo(0, 0)}
            className="link button button--color-pink"
          >
            Nous contacter
          </p>
          <React.Fragment>
            {entrepriseLogosData.displayMode === "slider" ? (
              <div className="slides logos--slider">
                <LogosSlider logos={formattedEntrepriseLogos} />
              </div>
            ) : (
              <div className="static-logos">
                {formattedEntrepriseLogos.map(image =>
                  image.fluidObject ? (
                    <Img
                      key={`partnershipType-restaurant-${image.alt}`}
                      fluid={image.fluidObject}
                      style={{ height: image.height, width: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      alt={image.alt}
                    />
                  ) : (
                    <img
                      loading="lazy"
                      src={image.publicURL}
                      height={image.height}
                    />
                  )
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      </section>
      <section id="distribution" className="two-cols">
        <div className="container">
          <h2 className="heading heading--section-title">
            Enseigne de la grande distribution
          </h2>
          <TwoCols image={images.grandeDistribution.childImageSharp}>
            <p>
              Les attentes des consommateurs changent, il faut s’adapter aux
              nouvelles envies de consommer. En effet, 88%* des Français sont
              favorables à la mise en place d’un système de consigne permettant
              le réemploi des bouteilles et emballages afin de lutter contre les
              plastiques à usage unique.
            </p>
            <p>
              Alors, ensemble nous pouvous faire bouger les choses en ayant un
              impact positif.
            </p>
            <ul>
              <li>
                Solution génératrice de trafic : 54% de taux de retour dans nos
                collecteurs installés en magasin{" "}
              </li>
              <li>Kit de communication inclus avec PLV</li>
              <li>Centralisation du lavage</li>
            </ul>
            <br />
            {/* <Testimonial
                image={images.franprix?.childImageSharp.fixed}
                text="Après un test à succès dans deux Franprix, nous avons décidé de déployer le service dans une vingtaine de magasins. Nos offres à emporter sont désormais consignées."
                signature="Anne, Franprix - Directrice RSE"
              />
              <Testimonial
                image={images.cocacola?.childImageSharp.fixed}
                text="Grâce aux collecteurs disponibles chez Franprix et au service de La Consigne GreenGo, nous pouvons récupérer nos bouteilles consignées et les laver dans notre centre logistique commun."
                signature="Anne, Coca-Cola - Directrice RSE"
              /> */}
          </TwoCols>
          <p
            onClick={() => scrollTo(0, 0)}
            className="link button button--color-pink"
          >
            Nous contacter
          </p>
          <React.Fragment>
            {grandeDistributionLogosData.displayMode === "slider" ? (
              <div className="slides logos--slider">
                <LogosSlider logos={formattedGrandeDistributionLogos} />
              </div>
            ) : (
              <div className="static-logos">
                {formattedGrandeDistributionLogos.map(image =>
                  image.fluidObject ? (
                    <Img
                      key={`partnershipType-restaurant-${image.alt}`}
                      fluid={image.fluidObject}
                      style={{ height: image.height, width: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      alt={image.alt}
                    />
                  ) : (
                    <img
                      loading="lazy"
                      src={image.publicURL}
                      height={image.height}
                    />
                  )
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      </section>
    </section>
  )
}

export default PartnershipTypes
